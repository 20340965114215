<template>
  <div class="position-relative h-100 mt--2 p-4">
    <div class="ad-service-page no-scroll">
      <AdService :id="id"></AdService>
    </div>
  </div>
</template>

<script>
import AdService from './AdService';

export default {
  name: 'AdServicePage',
  props: ['id'],
  components: {
    "AdService": AdService
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">

.ad-service-page {
  border: 1px solid rgba(0,0,0,.125);
  height: 82vh;
  width: 85%;
  position: sticky;
  position: -webkit-sticky;
  z-index: 11;
  top: 10.5vh;
  display: block;
  margin: auto;
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

</style>